import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Popconfirm } from 'antd';
import { UserContext } from '../../App';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function BranchTable({ deleteBranch, editPath, columnKeys, data }) {
  const user = useContext(UserContext);
  let searchInput;
  const { t } = useTranslation();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za '+ t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary'
          onClick={() => handleReset(clearFilters)} 
          // size='small' 
          // style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
    ),
  }));

  columns.push({
    title: 'Opcije',
    render: (record) => (
      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={editPath + record._id}>
            <EditOutlined
              title='Izmeni ogranak'
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>

        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            placement='left'
            title={`Da li želite da obrišete ${record.branchName}`}
            onConfirm={() => deleteBranch(record._id)}
            okText='U redu'
            cancelText='Poništi'
            cancelButtonProps={{className: 'b2b-secondary'}}
            okButtonProps={{className: 'b2b-primary'}}
          >
            <DeleteOutlined title={`Obriši ogranak`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
}
