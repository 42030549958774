import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import ViewBranches from '../pages/users/ViewBranches';
import AddCompanyBranch from '../pages/users/AddCompanyBranch';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import ViewOrder from '../pages/orders/ViewOrder';

const routes = [
  {
    label: 'Korisnici',
    showInMenu: ['superAdmin', 'companyAdmin'],
    children: [
      {
        label: 'Svi korisnici',
        path: '/company/users',
        component: Users,
        allowed: ['superAdmin', 'companyAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/company/new-user',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Izmena korisnika',
        path: '/company/edit-user/:userId',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/company/view-user/:id',
        component: ViewUser,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
      {
        label: 'Svi ogranci',
        path: '/company/branches',
        component: ViewBranches,
        allowed: ['companyAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi ogranak',
        path: '/company/new-branch',
        component: AddCompanyBranch,
        allowed: ['companyAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni ogranak',
        path: '/company/edit-branch/:id',
        component: AddCompanyBranch,
        allowed: ['companyAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Galenski lekovi',
    showInMenu: ['superAdmin', 'companyAdmin', 'company'],
    children: [
      {
        label: 'Svi lekovi',
        path: '/company/data',
        component: Data,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
      {
        label: 'Novi proizvod',
        path: '/company/new-data',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmena proizvoda',
        path: '/company/edit-data/:dataId',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/company/data-categories',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Dodavanje i izmena kategorije',
        path: '/company/data-categories/:categoryId',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/company/data-attributes',
        component: Attributes,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Trebovanja',
    showInMenu: ['superAdmin', 'companyAdmin', 'company'],
    children: [
      {
        label: 'Sva trebovanja',
        path: '/company/orders',
        component: Orders,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
      {
        label: 'Izmena trebovanja',
        path: '/company/edit-order/:orderId',
        component: EditOrder,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
      {
        label: 'Pregled trebovanja',
        path: '/company/view-order/:orderId',
        component: ViewOrder,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Porudžbenice',
    showInMenu: ['superAdmin', 'companyAdmin', 'company'],
    children: [
      {
        label: 'Sve porudžbenice',
        path: '/company/invoices',
        component: Invoices,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
    ],
  },
];

export default routes;
