import React from 'react';
import { Divider, Input, Form, Card, Select } from 'antd';

const { Option } = Select;

export default function CompanyBranch({ company, createBranch, updateBranch, branch }) {
  const [form] = Form.useForm();
  let isNew = true;

  // for editing the branch
  if (Object.keys(branch).length) {
    isNew = false;
    Object.keys(branch).forEach(value => form.setFieldsValue({ [value]: branch[value] }));
    form.setFieldsValue({ company: branch.company._id });
  }

  const onFinish = (values) => {
    if (isNew) {
      values.phone = [values.phone];
      createBranch(values);
    } else {
      updateBranch(values, branch._id);
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: "center" }}>
        <Card title={`${isNew ? 'Dodaj' : 'Ažuriraj'} ogranak`} bordered={false}>
          <Form
            className='form-horizontal'
            onFinish={(values) => onFinish(values)}
            layout='vertical'
            form={form}
          >
            <Form.Item label='Naziv ogranka' name='branchName'>
              <Input />
            </Form.Item>

            <Form.Item label='Kompanija' name='company'>
              <Select
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.key?.indexOf(input) >= 0}
              >
                {company?.companies?.map(company => <Option value={company._id} key={company.companyCode}>{company.companyName}</Option>)}
              </Select>
            </Form.Item>

            <Form.Item label='Adresa' name='address'>
              <Input />
            </Form.Item>

            <Form.Item label='Grad' name='city'>
              <Input />
            </Form.Item>

            <Form.Item label='Poštanski broj' name='zip'>
              <Input />
            </Form.Item>

            <Form.Item label='Država' name='country'>
              <Input />
            </Form.Item>

            <Form.Item label='Telefon' name='phone'>
              <Input />
            </Form.Item>
            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmlType='submit'>
                {`${isNew ? 'Kreiraj' : 'Ažuriraj'} ogranak`}
              </button>
            </div>
          </Form >
        </Card>
      </div >
    </div >
  );
}
