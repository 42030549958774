import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Layout, Tabs, Typography, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import moment from 'moment';
import EditUserModal from '../../components/modals/EditModal';

const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const [showModal, setShowModal] = useState(false);
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
      fetchLog(`${SERVER_URL}/logs/${id}`, []);
    }
  }, [id, fetchUser, fetchLog]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  useEffect(() => {
    if (id) {
      let sevenDayBefor = moment().subtract(7, 'day').format('YYYY-MM-DD');
      let sevenDayBeforGet = moment(sevenDayBefor).format('YYYY-MM-DD');
      let from = sevenDayBeforGet;
      let to = new Date();
      let fromTo = from + '||' + to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  }, [id, fetchCSV]);

  const updateUser = async (data) => {
    if (data) {
      let fromTo = data.from + '||' + data.to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  };

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <div className='edit-panel'>
      {currentuser.data.role.includes('superAdmin') && <div className='actions-block'>
        <Link to='/company/users'>
          <button className='b2b-primary'>Svi korisnici</button>
        </Link>
      </div>}

      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Nešto nije u redu :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} />

                {/*<Link to={'/company/edit-user/' + id}>*/}
                {/*   <Button
                  type='primary'
                  block
                  icon={<EditOutlined />}
                  style={{ marginTop: '1rem' }}
                  onClick={() => setShowModal(!showModal)}
                  disabled={currentuser.data.role[0] === 'company' && currentuser.data.id !== id}
                >
                  EDIT USER
                </Button>*/}
                {/*</Link>*/}
              </div>
              <Content style={{ padding: '15px', minHeight: 280, color: '#000' }}>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Email:</span> {user.data ? user.data.email : 'Email'}
                </p>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}>
                  <span className='bold-label'>Role:</span> {user.data ? user.data.role[0] : 'Role'}
                </p>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Status:</span> {user.data ? user.data.status : 'Status'}
                </p>
                {currentuser.data.id === id && (
                  <button style={{ width: '100%', fontSize: '14px' }}
                    className='b2b-primary' onClick={handleSignOutSubmit}>
                    Odjavi se
                  </button>
                )}
              </Content>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFIL' key='1'>
                  <div className='card-wrapper'>
                    <Card title='Informacije o korisniku' bordered={false}>
                      {user.data && user.data.role && user.data.role[0] === "companyAdmin" && (
                        <div>
                          <p>
                            <Text strong>Ime: </Text>
                            {user.data ? user.data.firstName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Prezime: </Text>
                            {user.data ? user.data.lastName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Email: </Text>
                            {user.data ? user.data.email : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Telefon: </Text>
                            {user.data ? user.data.phone[0] : 'Nema podataka'}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Role: </Text>
                            {user.data ? user.data.role[0] : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Status: </Text>
                            {user.data ? user.data.status : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Kompanija: </Text>
                            {user.data && user.data.companyAdminInfo && user.data.companyAdminInfo.length !== 0 ? user.data.companyAdminInfo.companyName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Adresa: </Text>
                            {user.data && user.data.companyAdminInfo && user.data.companyAdminInfo.length !== 0 ? user.data.companyAdminInfo.address : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Mesto: </Text>
                            <span>
                              {user.data && user.data.companyAdminInfo && user.data.companyAdminInfo.length !== 0 ? user.data.companyAdminInfo.city : 'Nema podataka'}
                              , &nbsp;
                              {user.data && user.data.companyAdminInfo && user.data.companyAdminInfo.length !== 0 ? user.data.companyAdminInfo.zip : 'Nema podataka'}
                            </span>
                          </p>
                          <p>
                            <Text strong>PIB: </Text>
                            {user.data && user.data.companyAdminInfo && user.data.companyAdminInfo.length !== 0 ? user.data.companyAdminInfo.taxId : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Matični broj: </Text>
                            {user.data && user.data.companyAdminInfo && user.data.companyAdminInfo.length !== 0 ? user.data.companyAdminInfo.companyNumber : 'Nema podataka'}
                          </p>
                        </div>
                      )}

                      {user.data && user.data.role && user.data.role[0] === "company" && (
                        <div>
                          <p>
                            <Text strong>Ime: </Text>
                            {user.data ? user.data.firstName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Prezime: </Text>
                            {user.data ? user.data.lastName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Email: </Text>
                            {user.data ? user.data.email : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Telefon: </Text>
                            {user.data ? user.data.phone[0] : 'Nema podataka'}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Role: </Text>
                            {user.data ? user.data.role[0] : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Status: </Text>
                            {user.data ? user.data.status : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Kompanija: </Text>
                            {user.data && user.data.companies && user.data.companies.length !== 0 ? user.data.companies[0].companyName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Ogranak: </Text>
                            {user.data && user.data.branch && user.data.branch.length !== 0 ? user.data.branch[0].branchName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Adresa ogranka: </Text>
                            {user.data && user.data.branch && user.data.branch.length !== 0 ? user.data.branch[0].address : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Mesto: </Text>
                            <span>
                              {user.data && user.data.branch && user.data.branch.length !== 0 ? user.data.branch[0].city : 'Nema podataka'}
                              , &nbsp;
                              {user.data && user.data.branch && user.data.branch.length !== 0 ? user.data.branch[0].zip : 'Nema podataka'}
                            </span>
                          </p>
                          <p>
                            <Text strong>PIB: </Text>
                            {user.data && user.data.companies && user.data.companies.length !== 0 ? user.data.companies[0].taxId : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Matični broj: </Text>
                            {user.data && user.data.companies && user.data.companies.length !== 0 ? user.data.companies[0].companyNumber : 'Nema podataka'}
                          </p>
                        </div>
                      )}

                      {user.data && user.data.role && user.data.role[0] === "superAdmin" && (
                        <div>
                          <p>
                            <Text strong>Ime: </Text>
                            {user.data ? user.data.firstName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Prezime: </Text>
                            {user.data ? user.data.lastName : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Email: </Text>
                            {user.data ? user.data.email : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Telefon: </Text>
                            {user.data ? user.data.phone[0] : 'Nema podataka'}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Role: </Text>
                            {user.data ? user.data.role[0] : 'Nema podataka'}
                          </p>
                          <p>
                            <Text strong>Status: </Text>
                            {user.data ? user.data.status : 'Nema podataka'}
                          </p>
                        </div>
                      )}
                    </Card>
                  </div>
                </TabPane>

                <TabPane tab='LOGOVI' key='2'>
                  {log.data.length > 0 ? (
                    <UserLog data={log.data} userId={id} updateHandler={updateUser} csv={csv.data} />
                  ) : (
                    'Nema podataka'
                  )}
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
      <EditUserModal userEdit={user.data} userId={id} showModal={showModal} setShowModal={setShowModal} profile={true} />
    </div>
  );
};

export default ViewUser;
