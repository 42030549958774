import React from 'react';
import { Button, Form, Row, Col, Typography, Card, Table, Input } from 'antd';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };

  const onFinish = async (values) => {
    onSubmit(values);
  };
  const columns = [
    {
      key: '1',
      title: 'product',
      dataIndex: 'name',
    },
    {
      key: '2',
      title: 'category',
      dataIndex: 'category',
    },
    {
      key: '3',
      title: 'discount',
      dataIndex: 'discount',
    },
    {
      key: '4',
      title: 'price',
      dataIndex: 'price',
    },
    {
      key: '5',
      title: 'quantity',
      dataIndex: 'quantity',
    },
    {
      key: '6',
      title: 'pdvTaxes',
      dataIndex: 'pdvTaxes',
    },
    {
      key: '7',
      title: 'Subtotal',
      dataIndex: 'subtotal',
    },
  ];

  let city = '';
  let address = '';
  let zip = '';
  let phone = '';
  if (data) {
    if (!data.company) {
      if (data.user.role[0] === 'companyAdmin') {
        city = data.user.companyAdminInfo.city;
        address = data.user.companyAdminInfo.address;
        zip = data.user.companyAdminInfo.zip;
        phone = data.user.companyAdminInfo.phone;
      } else if (data.user.role[0] === 'company') {
        city = data.user.companies[0].city;
        address = data.user.companies[0].address;
        zip = data.user.companies[0].zip;
        phone = data.user.companies[0].phone[0];
      }
    } else {
      city = data.company.city;
      address = data.company.address;
      zip = data.company.zip;
      phone = data.company.phone;
    }
  }

  data.order.forEach((record, i) => record.discountPercentage ? data.order[i].subtotal = (record.quantity * (record.price - record.price * +record.discountPercentage / 100)).toFixed(2) : data.order[i].subtotal = (record.quantity * record.price).toFixed(2));

  data.order.forEach((record, i) => {
    if (record.discountPercentage)
      data.order[i].discount = `${record.discountPercentage}%`;
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji trebovanja' bordered={false}>
                  <p>
                    <Text strong>Broj trebovanja: </Text>
                    {data.orderNumber ? data.orderNumber : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {data.company?.branchName ? data.company?.branchName : data.branch?.companyName ? data.branch?.companyName : ''}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {city}
                  </p>
                  <p>
                    <Text strong>Adresa: </Text>
                    {address}
                  </p>
                  <p>
                    <Text strong>Telefon: </Text>
                    {phone}
                  </p>
                  <p>
                    <Text strong>Poštanski broj: </Text>
                    {zip}
                  </p>
                  <p>
                    <Text strong>Ukupna cena: </Text>
                    {data.totalPrice ? (+data.totalPrice).toFixed(2) : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Ukupna cena sa PDV: </Text>
                    {data.totalPriceWithTax ? (+data.totalPriceWithTax).toFixed(2) : 'Nema podataka'}
                  </p>
                </Card>
                <br /><br />
                <Form.Item label='Status trebovanja' name='status'>
                  <Input disabled style={{ color: 'black' }}></Input>
                </Form.Item>
                {onSubmit && <div className='text-right'>
                  <Button type='primary' htmlType='submit'>
                    Update
                  </Button>
                </div>}
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Table
                  pagination={false}
                  rowKey='_id'
                  style={{ margin: '0px 0px 40px 0px' }}
                  columns={columns}
                  dataSource={data.order}
                  bordered
                />
                {createInvoice && <div className='text-right'>
                  <Button type='primary' onClick={createInvoice}>
                    Kreiraj račun
                  </Button>
                </div>}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div >
  );
};

export default OrderForm;
