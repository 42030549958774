import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import OrderForm from '../../components/forms/OrderForm';

const ViewData = (props) => {
  //const history = useHistory();
  const currentuser = useContext(UserContext);
  const [order, fetchOrder] = useAxios('', {}, currentuser.data.token, 'get');
  const { orderId } = props.match.params;

  useEffect(() => {
    if (orderId) fetchOrder(`${SERVER_URL}/orders/${orderId}`, {});
  }, [fetchOrder, orderId]);

  const isDataFetched = !order.isLoading && Object.keys(order.data).length > 0 && currentuser.language;
  // if (isDataFetched) {
  //   order.data.user.userName = order.data.user.firstName + ' ' + order.data.user.lastName;
  // }
  return (
    <div className='table'>
      <div className='actions-block' style={{ paddingLeft:'45px', paddingTop:'30px' }}>
        <Link to='/company/orders'>
          <button className='b2b-primary'>Sva trebovanja</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {orderId && !order.isError && order.data && isDataFetched && (
          <OrderForm
            data={order.data}
            language={currentuser.language}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewData;
