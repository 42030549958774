import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Row, Col, Select, Divider } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import OrderedProductsTable from '../../components/tables/OrderedProductsTable';
import { SERVER_URL } from '../../config';

const Data = () => {
  const currentuser = useContext(UserContext);
  const userId = currentuser.data.id;
  const [companies, setCompanies] = useState([]);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [loggedUser, fetchLoggedUser] = useAxios('', [], currentuser.data.token, 'get');
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [allTotal, setAllTotal] = useState(0);
  const [taxId, setTaxId] = useState();
  const [companyName, setCompanyName] = useState();
  const [address, setAddress] = useState();
  const [branches, setBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  const header = useMemo(() => ({
    withCredentials: false,
    headers: { Authorization: `Bearer ${currentuser.data.token}` },
  }), [currentuser.data.token]);

  const getCompanies = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/companies`, header);
      setCompanies(data.items);
    } catch (err) {
      console.log(err.message);
    }
  }, [header]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const getCart = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/cart/${userId}`, header);
      setTotal(data.products.reduce((a, b) => a + parseFloat(b.wholesalePrice) * b.amount, 0));
      setAllTotal(data.products.reduce((a, b) => {
        const pdvTaxes = b.pdvTaxes !== null && b.pdvTaxes !== undefined ? b.pdvTaxes : 10;
        return a + parseFloat(b.wholesalePrice) * b.amount * (1 + (pdvTaxes / 100));
      }, 0));
      setCart(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [header, userId]);

  useEffect(() => {
    getCart();
    fetchData(`${SERVER_URL}/data`, []);
    fetchLoggedUser(`${SERVER_URL}/users-company/${userId}`, []);
  }, [fetchData, fetchLoggedUser, userId, getCart]);

  const addToCart = async (item) => {
    const json = {
      user: userId,
      products: [
        {
          product: item._id,
          amount: 1,
          title: item.title.sr,
          code: item.code,
          category: item.category.name.sr,
          wholesalePrice: item.wholesalePrice,
          pdvTaxes: item.pdvTaxes,
        },
      ],
    };
    try {
      const { data } = await Axios.put(`${SERVER_URL}/cart/${userId}`, json, header);
      getCart();
      notification.info({
        message: data.message,
        placement: "bottomRight",
        duration: 1
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const removeFromCart = async (id) => {
    try {
      const { data } = await Axios.put(`${SERVER_URL}/cart/${userId}?id=${id}`, {}, header);
      getCart();
      notification.info({
        message: data.message,
        placement: "bottomRight",
        duration: 1
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeAmount = async (id, verb) => {
    try {
      const data = {
        product: id,
        change: verb
      };
      await Axios.put(`${SERVER_URL}/cart/amount/${userId}`, data, header);
      getCart();
    } catch (error) {
      console.log(error.message);
    }
  };

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.wholesalePrice = +(item.wholesalePrice).toFixed(2);
      item.wholesalePriceWithTax = (item.wholesalePrice * (1 + (item.pdvTaxes !== undefined ? item.pdvTaxes : 10) / 100)).toFixed(2);
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (typeof item.inStock === 'boolean') {
        item.inStock =
          item.inStock === true ? (
            <span>
              <CheckCircleFilled /> Na stanju
            </span>
          ) : (
            <span>
              <CloseCircleFilled /> Nema na stanju
            </span>
          );
      }
      return item;
    });
  }

  const emptyOutFields = () => {
    setCompanyName(undefined);
    setAddress(undefined);
    setSelectedCompany(undefined);
    setTaxId(undefined);
  };

  const changeCustomerData = (id) => {
    if (!id) {
      emptyOutFields();
    } else {
      for (const company of branches) {
        if (company._id === id) {
          setCompanyName(company.branchName);
          setAddress(`${company.address}, ${company.zip}, ${company.city}`);
          setSelectedCompany(company._id);
          setTaxId(company.taxId);
        }
      }
    }
  };

  const finishPurchase = async () => {
    let company;
    if (selectedCompany) {
      company = selectedCompany;
    }

    if (currentuser.data.role.includes('superAdmin')) {
      if (!company || !branches) {
        notification.warn({
          message: "Molim da izaberete kompaniju i njen ogranak",
          duration: 2.5,
          placement: 'bottomRight'
        });
        return 0;
      }
    }

    if (!address || !companyName) {
      notification.warn({
        message: "Molim da izaberete ogranak",
        duration: 2.5,
        placement: 'bottomRight'
      });
      return 0;
    }

    const orders = cart.products.map(product => ({
      name: product.title,
      quantity: product.amount,
      category: product.category,
      price: product.wholesalePrice,
      code: product.code,
      pdvTaxes: product.pdvTaxes !== undefined ? product.pdvTaxes : 10,
    }));
    const data = {
      order: orders,
      user: loggedUser.data,
      company,
      totalPrice: total.toFixed(2),
    };
    data.branch = {
      companyName,
      address: address.split(', ')[0],
      zip: address.split(', ')[1],
      city: address.split(', ')[2],
      taxId,
    };
    await Axios.post(`${SERVER_URL}/orders`, data, header);
    notification.success({
      message: 'Kupovina je obavljena',
      placement: 'bottomRight',
    });

    setCart([]);
    setTotal();
    emptyOutFields();
  };

  const getBranches = useCallback(async (companies) => {
    const { data } = await Axios.post(`${SERVER_URL}/company/branches`, { companies }, header);
    setBranches(data);
  }, [header]);

  useEffect(() => {
    if (loggedUser.data && loggedUser.data.companies) {
      const companyIds = [];
      loggedUser.data.companies.forEach((item) => {
        companyIds.push(item._id);
      });
      getBranches(companyIds);
    }
  }, [currentuser.data.role, getBranches, loggedUser.data]);

  return (
    <div className='table'>
      {currentuser.data.role.includes('superAdmin') && (
        <div className='actions-block'>
          <Link to='/company/new-data'>
            <Button type='primary'>Novi lek</Button>
          </Link>
        </div>
      )}

      {cart?.products?.length && loggedUser.data &&
        loggedUser.data.length !== 0 ? (
        <Row style={{ margin: '1rem 0' }}>
          <Col span={16}>
            <OrderedProductsTable
              data={cart?.products}
              removeFromCart={removeFromCart}
              changeAmount={changeAmount}
              columnKeys={['wholesalePrice', 'inStock', 'code', 'pdvTaxes']}
              title='OrderedProducts'
            />
          </Col>
          <Col className='columnCompany' span={7}>
            <div className='headerzaisporuku'>
              <span>Za isporuku</span>
            </div>
            <span>
              {currentuser.data.role.includes('companyAdmin') && <Select
                allowClear={true}
                placeholder='Izaberite ogranak'
                onChange={(value) => changeCustomerData(value)}
                value={selectedCompany && selectedCompany._id}
              >
                {loggedUser.data &&
                  loggedUser.data.length !== 0 &&
                  branches.map((item) => (
                    <Select.Option value={item._id}>{item.branchName}</Select.Option>
                  ))}
              </Select>}

              {currentuser.data.role.includes('superAdmin') && <Select
                allowClear={true}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder='Izaberite kompaniju'
                onSelect={(id) => getBranches(id)}
              >
                {companies?.map((item) => (
                  <Select.Option value={item._id}>{item.companyName}</Select.Option>
                ))}
              </Select>}

              {!currentuser.data.role.includes('companyAdmin') && branches?.length ? <>
                <Divider type='horizontal' />
                <Select
                  allowClear={true}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder='Izaberite ogranak'
                  onChange={(value) => changeCustomerData(value)}
                >
                  {branches?.map((item) => (
                    <Select.Option value={item._id}>{item.branchName}</Select.Option>
                  ))}
                </Select>
              </> : null}
            </span>
            <Divider type='horizontal' />
            <span>
              Adresa: {address}
            </span>
            <Divider type='horizontal' />
            <Row>
              <Col span={16}>Ukupno bez PDVa</Col>
              <Col span={8}>{(total).toFixed(2)} Rsd</Col>
            </Row>
            <Row>
              <Col span={16}>PDV</Col>
              <Col span={8}>{parseFloat(allTotal - total).toFixed(2)} Rsd</Col>
            </Row>
            <Divider type='horizontal' />
            <Row>
              <Col span={16}>Za plaćanje</Col>
              <Col span={8}>{(allTotal).toFixed(2)} Rsd</Col>
            </Row>
            <Divider type='horizontal' />
            <div
              className='button-div'
              style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '5px' }}
            >
              <button className='b2b-primary' onClick={finishPurchase}>Završi kupovinu</button>
            </div>
            <br />
          </Col>
        </Row>
      ) : <></>}

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            inCart={cart?.products}
            data={tableData}
            columnKeys={['title', 'wholesalePrice', 'wholesalePriceWithTax', 'inStock', 'code', 'pdvTaxes']}
            addToCart={addToCart}
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 &&
          <div className='no-data-box'>
            <h2>NEMA PODATAKA</h2>
          </div>
        }
      </div>
    </div>
  );
};

export default Data;
